//components
import homeHeader from './components/homeHeader';
import homeFooter from './components/homeFooter';
import homeMenu from './components/homeMenu';
import homeUpgrade from './components/homeUpgrade';

// methods
import { getHomeMenu, postGradeUpgrade, getLoginDispatch } from '@/api/common';
import { menuConfig, MANAGEMENT, CLASS_BOARD, BIG_DATA } from './config';

import { getUpgradeSetting } from '@/api/basic';

export default {
  name: 'Home',
  props: {},
  data() {
    return {
      visible: false,
      school: {}, //学校信息
      menu: [],
      pageData: {},
      isShowUpgrade: false, // 升年级弹窗
      periodList: [],
      showTipTime: {},

      clientType: '', // 校层/云层
      fullscreenLoading: false,
    };
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
  },
  components: {
    homeHeader,
    homeFooter,
    homeMenu,
    homeUpgrade,
  },
  created() {
    this.init();
  },
  mounted() {
    window.entryTitle && (document.title = window.entryTitle);
  },
  methods: {
    init() {
      // 获取菜单
      this.getHomeMenu();
    },

    getHomeMenu() {
      getHomeMenu().then((res) => {
        const { checkShowTip, school, menuNewList, needRedirect, hasClassLesson } = res.result;
        const { userType } = this.userInfo;

        let menuList = [];
        menuNewList.forEach((item) => {
          menuList = menuList.concat(item.menuList);
        });

        // 只有一个菜单项的时候
        if (
          (needRedirect && userType !== 4 && userType !== 12) ||
          (needRedirect == true && (userType === 4 || userType === 12) && hasClassLesson == true)
        ) {
          const item = menuList[0];
          switch (item.code) {
            case MANAGEMENT:
              this.$router.push('/back-stage').catch();
              return;
            default:
              window.location.href = `${window.$ctx}/${item.linkUrl}`;
              break;
          }
          return;
        } else {
          this.visible = true;
        }

        this.pageData = res.result;
        this.school = school;
        this.handleMenu(menuList);
        if (checkShowTip) {
          this.getUpgradeSetting();
        }
      });
    },

    getUpgradeSetting() {
      getUpgradeSetting().then((res) => {
        if (res && res.status === 0) {
          let {
            setting: { showTipTime, periodList },
          } = res.result;
          for (let i = 0; i < periodList.length; i++) {
            let res = [];
            const item = periodList[i];
            for (let j = 0; j < item.gradeTypeList.length; j++) {
              if (item.gradeTypeList[j].withStudent === 1) {
                res.push(item.gradeTypeList[j].gradeTypeId);
              }
            }
            item.checkList = res;
          }

          this.periodList = periodList;
          this.showTipTime = showTipTime;
          this.isShowUpgrade = true;
        }
      });
    },

    handleMenu(list) {
      for (let i = 0; i < list.length; i++) {
        Object.assign(list[i], menuConfig[list[i].code]);
      }
      list.sort((a, b) => a.rowIndex - b.rowIndex);
      this.menu = list;
    },

    handleClick(item) {
      const { hasClassLesson } = this.pageData;
      const { userType } = this.userInfo;

      if (
        (userType === 4 || userType === 12 || userType === 8) &&
        item.code === CLASS_BOARD &&
        !this.school.isAdmin &&
        !hasClassLesson
      ) {
        this.$msgbox.alert(
          userType === 8 ? '用户暂无任教信息，请添加任教信息' : '用户暂无任教信息，请联系学校管理员添加任教信息',
          '提示',
          {
            confirmButtonText: '确定',
          }
        );
        return;
      }

      // 对智慧作业、教学大数据增加token后跳转
      switch (item.code) {
        case MANAGEMENT:
          this.$router.push('/back-stage');
          break;
        case BIG_DATA:
          window.open(`${window.$ctx}/${item.linkUrl}`);
          break;
        default:
          window.location.href = `${window.$ctx}/${item.linkUrl}`;
          break;
      }
    },

    handleUpgrade() {
      let res = [];
      for (let i = 0; i < this.periodList.length; i++) {
        const item = this.periodList[i],
          checkList = item.checkList;
        for (let j = 0; j < item.gradeTypeList.length; j++) {
          if (checkList.indexOf(item.gradeTypeList[j].gradeTypeId) > -1) {
            item.gradeTypeList[j].withStudent = 1;
          } else {
            item.gradeTypeList[j].withStudent = 0;
          }
          res.push(item.gradeTypeList[j]);
        }
      }
      const data = JSON.stringify(res);
      this.fullscreenLoading = true;
      postGradeUpgrade({
        schoolId: this.school.schoolId,
        data,
      })
        .then((res) => {
          if (res && res.result === 0) {
            this.$message.success('升年级成功!');
            this.isShowUpgrade = false;
          }
        })
        .finally(() => {
          this.fullscreenLoading = false;
        });
    },

    getLoginDispatch() {
      return new Promise((resolve) => {
        if (this.clientType) {
          return resolve(this.clientType);
        }
        getLoginDispatch()
          .then((res) => {
            const { clientType } = res.result;
            this.clientType = clientType;
            resolve(clientType);
          })
          .catch(() => {
            resolve();
          });
      });
    },
  },
};
