export const CLASSROOM = 'ketang'; // 青小鹿课堂
export const CLASS_ANALYSIS = 'zhihuiketangfenxi'; // 智慧课堂分析
export const BIG_DATA = 'jiaoxuedashujukanban'; // 数据大看板
export const CLASS_BOARD = 'qingluguanjia'; // 青小鹿管家
export const EXERCISE = 'zhihuizuoye'; // 青小鹿测练EXERCISE
export const HOMEWORK = 'qingxiaoluzuoye'; // 青小鹿作业
export const HOMEWORK_MONITOR = 'jiaofuzuoyejiance'; // 教辅作业监测
export const RESOURCES = 'jiaoxueziyuan'; // 教学资源
export const MANAGEMENT = 'jichushuju'; // 后台管理
export const GROUP = 'fenzuguanli'; // 分组管理

export const menuConfig = {
  [CLASSROOM]: {
    title: '青小鹿课堂',
    subTitle: 'INTELLIGENT CLASSROOM',
    imgClass: 'IntelligentClassroom',
  },
  [CLASS_ANALYSIS]: {
    title: '智慧课堂分析',
    subTitle: 'ACADEMIC ANALYSIS',
    imgClass: 'AcademicAnalysis',
  },
  [BIG_DATA]: {
    title: '教学数据看板',
    subTitle: 'TEACHING DATA',
    imgClass: 'IntelligentBigData',
  },
  [CLASS_BOARD]: {
    title: '青小鹿管家',
    subTitle: 'INTELLIGENT CLASS BOARD',
    imgClass: 'IntelligentClassBoard',
  },
  [EXERCISE]: {
    title: '青小鹿测练',
    subTitle: 'SMART TEST',
    imgClass: 'IntelligentExercise',
  },
  [HOMEWORK]: {
    title: '青小鹿作业',
    subTitle: 'SMART HOMEWORK',
    imgClass: 'IntelligentHomework',
  },
  [HOMEWORK_MONITOR]: {
    title: '作业监测报告',
    subTitle: 'ASSIGNMENT DATA',
    imgClass: 'JobDetection',
  },
  [RESOURCES]: {
    title: '教学资源',
    subTitle: 'RESOURCE',
    imgClass: 'Resource',
  },
  [MANAGEMENT]: {
    title: '后台管理',
    subTitle: 'MANAGEMENT',
    imgClass: 'Management',
  },
  [GROUP]: {
    title: '分组管理',
    subTitle: 'GROUP',
    imgClass: 'Group',
  },
};
