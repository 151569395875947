<template>
  <qlHeader :bgColor="'#20242D'">
    <div slot="logo" class="logo">
      <img :src="logo" />
      <p>青鹿教学云平台</p>
    </div>
    <p slot="list" class="header_school" v-show="schoolName">{{ schoolName }}</p>
  </qlHeader>
</template>

<script>
import logo from '@images/home/home_logo.png';
import qlHeader from '@/components/header';

export default {
  components: {
    qlHeader,
  },
  props: {
    schoolName: {
      type: String,
    },
  },
  data() {
    return {
      logo,
    };
  },
};
</script>

<style lang="scss" scoped>
.header_school {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  padding-right: 16px;
  border-right: 1px solid #555555;
  margin-right: 16px;

  .logo {
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 1px;
    align-items: center;
    display: flex;

    img {
      margin-right: 16px;
    }
  }
}
</style>
