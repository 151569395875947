<template>
  <footer class="homeFooter">
    <div class="feng-gang-tips" v-if="isShowFGTip">该版本专为凤岗中心小学定制</div>
    <copyright-html>
      <div class="service">
        <a
          href="http://system.eduyun.cn/bmp-web/getSpAppDetail_index?appId=gzRhfofDep50yCNue1evpOKCjsjeaXgU&userId=GzpHLVVf4mzOmVUliKJIQ1wqqkbfqrtO"
          target="_blank"
          >评价</a
        >
        <span class="slide-dash">|</span>
        <a
          href="http://system.eduyun.cn/bmp-web/sysAppReport/appReport?appId=gzRhfofDep50yCNue1evpOKCjsjeaXgU&userId=GzpHLVVf4mzOmVUliKJIQ1wqqkbfqrtO"
          target="_blank"
          >举报</a
        >
      </div>
    </copyright-html>
  </footer>
</template>
<script>
// components
import copyrightHtml from '@/components/copyrightHtml';

export default {
  name: 'HomeFooter',
  components: {
    copyrightHtml,
  },
  props: {
    school: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  computed: {
    // 判断当前学校是否为"东莞市凤岗镇中心小学"
    isShowFGTip() {
      if (this.school && this.school.schoolName === '东莞市凤岗镇中心小学') {
        return true;
      }
      return false;
    },
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.homeFooter {
  .feng-gang-tips {
    position: absolute;
    bottom: 68px;
    width: 100%;
    margin-bottom: 10px;
    text-align: center;
    color: #6d7171;
  }

  .service {
    margin-top: 10px;
    font-size: 12px;
    letter-spacing: 1px;
  }

  .service .slide-dash {
    margin: 0 5px;
    vertical-align: 2px;
  }
}
</style>
