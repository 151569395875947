<template>
  <ul class="homeMenu" :style="menuStyle">
    <li class="list_item" v-for="(item, index) in menu" :key="index" :style="menuItemStyle" @click="handleClick(item)">
      <p class="list_item-img" :class="[item.imgClass]"></p>
      <p class="list_item-title">{{ item.title }}</p>
      <p class="list_item-subtitle">{{ item.subTitle }}</p>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'HomeMenu',
  props: {
    menu: {
      type: Array,
    },
  },
  computed: {
    menuStyle() {
      if (this.menu?.length) {
        const menuLength = this.menu.length;
        if (menuLength <= 4) {
          return { width: menuLength * 300 + 'px' };
        } else if (menuLength === 6) {
          return { width: '1440px' };
        } else if (menuLength > 6) {
          return { 'justify-content': 'flex-start' };
        }
      }
      return {};
    },

    menuItemStyle() {
      return this.menu && this.menu.length > 6 ? { margin: '0 10px' } : {};
    },
  },
  methods: {
    handleClick(item) {
      this.$emit('handleClick', item);
    },
  },
};
</script>

<style lang="scss" scoped>
.homeMenu {
  width: 1200px;
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  transform: translate(-50%, -50%);
  margin-top: -60px;

  .list_item {
    width: 220px;
    height: 260px;
    padding-bottom: 10px;
    text-align: center;
    color: #ffffff;
    line-height: 20px;
    cursor: pointer;

    &-img {
      width: 220px;
      height: 200px;
      background-repeat: no-repeat;
      background-color: transparent;
      background-size: contain;
    }

    &-title {
      font-size: 22px;
      font-weight: bold;
      opacity: 0.7;
    }

    &-subtitle {
      font-size: 12px;
      opacity: 0.15;
      margin: 11px 0;
    }

    &:hover,
    &:active {
      .list_item-title {
        opacity: 1;
      }
      .list_item-subtitle {
        opacity: 0.2;
      }

      .IntelligentBigData {
        background-image: url('~@images/home/home_IntelligentBigData_2.png');
      }

      .IntelligentClassBoard {
        background-image: url('~@images/home/home_IntelligentClassBoard_2.png');
      }

      .IntelligentClassroom {
        background-image: url('~@images/home/home_IntelligentClassroom_2.png');
      }

      .IntelligentExercise {
        background-image: url('~@images/home/home_IntelligentTest_2.png');
      }

      .IntelligentHomework {
        background-image: url('~@images/home/home_IntelligentHomework_2.png');
      }

      .Management {
        background-image: url('~@images/home/home_Management_2.png');
      }

      .JobDetection {
        background-image: url('~@images/home/home_JobDetection_2.png');
      }

      .Group {
        background-image: url('~@images/home/home_IntelligentGroup_2.png');
      }

      .AcademicAnalysis {
        background-image: url('~@images/home/home_AcademicAnalysis_2.png');
      }

      .Resource {
        background-image: url('~@images/home/home_Resource_2.png');
      }
    }
  }

  .IntelligentBigData {
    background-image: url('~@images/home/home_IntelligentBigData_1.png');
  }

  .IntelligentClassBoard {
    background-image: url('~@images/home/home_IntelligentClassBoard_1.png');
  }

  .IntelligentClassroom {
    background-image: url('~@images/home/home_IntelligentClassroom_1.png');
  }

  .IntelligentExercise {
    background-image: url('~@images/home/home_IntelligentTest_1.png');
  }

  .IntelligentHomework {
    background-image: url('~@images/home/home_IntelligentHomework_1.png');
  }

  .Management {
    background-image: url('~@images/home/home_Management_1.png');
  }

  .JobDetection {
    background-image: url('~@images/home/home_JobDetection_1.png');
  }

  .Group {
    background-image: url('~@images/home/home_IntelligentGroup_1.png');
  }

  .AcademicAnalysis {
    background-image: url('~@images/home/home_AcademicAnalysis_1.png');
  }

  .Resource {
    background-image: url('~@images/home/home_Resource_1.png');
  }
}
</style>
